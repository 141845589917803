import { useCallback } from 'react';

const useValidate = () => {
    const validateTextExists = useCallback((name: string) => name?.length >= 1, []);

    const validateEmail = useCallback((email: string) => {
        const emailRules = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        return emailRules.test(email);
    }, []);

    return {
        validateEmail,
        validateTextExists
    };
};

export default useValidate;
