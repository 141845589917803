import React from 'react';
import type { Account } from '@wilm/shared-types/account';
import LoginForm from './login-form';

export interface LoginProps {
    onLogin?: (account: Account) => void;
    closeLoginModal?: () => void;
    registrationNoteContent: string;
}

const Login: React.FC<LoginProps> = ({ onLogin, registrationNoteContent, closeLoginModal }) => {
    return <LoginForm onLogin={onLogin} registrationNoteContent={registrationNoteContent} closeLoginModal={closeLoginModal} />;
};

export default Login;
